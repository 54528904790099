import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import Link from '../utils/link'

import Seo from '../components/seo'
import Thanks from '../assets/images/thank-you.jpg'
import Logo from '../assets/images/logo.svg'

class ThanksPage extends Component {

  render() {
    return (
    	<>
	    	<Seo title='Thank You - Lenoxx' bodyClass='thank-you' description='Thank you for registering your Lenoxx purchase.' />
	      <section className='thanks'>
	        <img className='thanks__bg' src={Thanks} alt='Thank You' />
	        <div className='thanks__wrapper'>
	        	<img className='thanks__logo' src={Logo} alt="Lenoxx" />
	        	<h1>Thank you for registering your Lenoxx purchase.</h1>
	        	<div className='thanks__buttons'>
	        		<Link className='btn btn--yellow' to='https://lenoxx-electronics.myshopify.com/collections/new-arrivals'>Show New Arrivals</Link>
	        		<Link className='btn btn--outline' to='https://lenoxx-electronics.myshopify.com/'>Back to Home</Link>
	        	</div>
	        </div>
	      </section>
      </>
    )
  }
}

export default ThanksPage
